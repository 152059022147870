<template>
  <div class="welcome-container">
    <div class="welcome-content">
      <!-- Different title based on customer type -->
      <h1 class="welcome-title" v-if="isNexos">Welcome to Nexos</h1>
      <h1 class="welcome-title" v-else-if="isIntercontinentalAlliance">Welcome to Intercontinental Alliance</h1>
      <h1 class="welcome-title" v-else>Welcome to Nectios</h1>
      
      <!-- Different content based on customer type -->
      <template v-if="isNexos">
        <p class="welcome-text">You will be redirected inside in few seconds</p>
        <div class="countdown" v-if="countdown > 0">Redirecting in {{ countdown }} seconds...</div>
        
        <div class="welcome-instructions">
          <a 
            :href="redirectUrl" 
            class="nexos-link"
          >
            <b-button
              variant="primary"
              size="lg"
              class="action-button"
            >
              Enter Nexos
            </b-button>
          </a>
        </div>
      </template>
      
      <template v-else>
        <p class="welcome-text">Your platform for building and managing vibrant communities</p>
        
        <div class="welcome-instructions">
          <p class="instruction-text">You need to look for a community</p>
          <p class="instruction-text">- OR -</p>
          <a 
            :href="myNectiosUrl" 
            class="my-nectios-link"
            target="_blank"
          >
            <b-button
              variant="primary"
              size="lg"
              class="action-button"
            >
              Access My Nectios
            </b-button>
          </a>
        </div>
      </template>
      
      <div v-if="previousUrl && !isNexos" class="previous-url-container">
        <p class="previous-url-text">You were trying to access: <strong>{{ previousUrlDisplay }}</strong></p>
        <p>Do you want to continue accessing it?</p>
        <b-button
          variant="secondary"
          size="md"
          @click="navigateToPreviousUrl"
          class="previous-url-button"
        >
          Continue to Previous Page
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WelcomeToNectios',
  
  data() {
    return {
      previousUrl: null,
      countdown: 5,
      redirectTimer: null,
      redirectAttempted: false
    };
  },
  
  computed: {
    isNexos() {
      return process.env.VUE_APP_CUSTOMER === 'nexos';
    },
    isIntercontinentalAlliance() {
      const isIntercontinentalDomain = window.location.hostname.includes('intercontinentalalliance.com');
      return isIntercontinentalDomain || process.env.VUE_APP_CUSTOMER === 'intercontinentalalliance';
    },
    
    shouldRedirectImmediately() {
      return this.isNexos || this.isIntercontinentalAlliance;
    },
    
    myNectiosUrl() {
      return process.env.VUE_APP_MY_NECTIOS_URL || 'https://my.nectios.com';
    },
    
    redirectUrl() {
      const baseUrl = process.env.VUE_APP_URL || '';
      const slug = process.env.VUE_APP_MAIN_SPACE_SLUG || '';
      return `${baseUrl}/${slug}`;
    },

    redirectUrlIntercontinentalAlliance() {
      const baseUrl = 'https://app.intercontinentalalliance.com';
      const slug = 'intercontinental-alliance';
      return `${baseUrl}/${slug}`;
    },
    
    previousUrlDisplay() {
      if (!this.previousUrl) return '';
      
      const url = new URL(this.previousUrl);
      return url.pathname.length > 40 
        ? url.hostname + url.pathname.substring(0, 40) + '...' 
        : url.hostname + url.pathname;
    }
  },
  
  methods: {
    navigateToPreviousUrl() {
      if (this.previousUrl) {
        window.location.href = this.previousUrl;
      }
    },
    
    getPreviousUrl() {
      const urlParams = new URLSearchParams(window.location.search);
      const fromParam = urlParams.get('from');
      
      if (fromParam) {
        try {
          const url = new URL(fromParam);
          return fromParam;
        } catch (e) {
          // Not a valid URL
        }
      }
      
      if (document.referrer && document.referrer !== window.location.href) {
        return document.referrer;
      }
      
      const storedUrl = sessionStorage.getItem('previousUrl');
      if (storedUrl) {
        return storedUrl;
      }
      
      return null;
    },
    
    performRedirect() {
      if (this.redirectAttempted) return;
      this.redirectAttempted = true;
      
      if (this.isNexos) {
        window.location.replace(this.redirectUrl);
      } else if (this.isIntercontinentalAlliance) {
        window.location.replace(this.redirectUrlIntercontinentalAlliance);
      }
    }
  },
  
  beforeCreate() {
    // Handle redirects before component is even created
    const isNexos = process.env.VUE_APP_CUSTOMER === 'nexos';
    const isIntercontinental = window.location.hostname.includes('intercontinentalalliance.com') || 
                              process.env.VUE_APP_CUSTOMER === 'intercontinentalalliance';
    
    if (isNexos || isIntercontinental) {
      const baseUrl = isNexos 
        ? (process.env.VUE_APP_URL || '')
        : 'https://app.intercontinentalalliance.com';
      
      const slug = isNexos 
        ? (process.env.VUE_APP_MAIN_SPACE_SLUG || '')
        : 'intercontinental-alliance';
      
      window.location.replace(`${baseUrl}/${slug}`);
    }
  },
  
  created() {
    if (this.shouldRedirectImmediately) {
      this.performRedirect();
    } else {
      this.previousUrl = this.getPreviousUrl();
      if (this.previousUrl) {
        console.log('saving previousUrl in sessionStorage:', this.previousUrl);
        sessionStorage.setItem('previousUrl', this.previousUrl);
      }
    }
  },
  
  render(h) {
    // Don't render anything for immediate redirect cases
    if (this.shouldRedirectImmediately) {
      return h();
    }
    
    // Return the regular template for other cases
    return h('div', { class: 'welcome-container' }, [
      h('div', { class: 'welcome-content' }, [
        h('h1', { class: 'welcome-title' }, 'Welcome to Nectios'),
        h('p', { class: 'welcome-text' }, 'Your platform for building and managing vibrant communities'),
        // ... rest of your template structure
      ])
    ]);
  }
}
</script>

<style scoped>
.welcome-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #f5f7fa 0%, #e4e7eb 100%);
  padding: 20px;
}

.welcome-content {
  text-align: center;
  max-width: 600px;
  padding: 40px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.welcome-title {
  font-size: 3rem;
  color: #2c3e50;
  margin-bottom: 1rem;
  font-weight: bold;
}

.welcome-text {
  font-size: 1.25rem;
  color: #606f7b;
  margin-bottom: 2rem;
}

.countdown {
  font-size: 1rem;
  color: #718096;
  margin-bottom: 1.5rem;
  font-style: italic;
}

.welcome-instructions {
  margin-bottom: 2rem;
}

.instruction-text {
  font-size: 1.5rem;
  color: #2c3e50;
  margin-bottom: 1rem;
}

.action-button {
  min-width: 200px;
  margin: 1rem 0;
}

.my-nectios-link, .nexos-link {
  text-decoration: none;
  display: inline-block;
}

.previous-url-container {
  margin-top: 2rem;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  border: 1px solid #e9ecef;
}

.previous-url-text {
  margin-bottom: 0.5rem;
}

.previous-url-button {
  margin-top: 0.75rem;
}

@media (max-width: 640px) {
  .welcome-title {
    font-size: 2.2rem;
  }
  
  .welcome-text {
    font-size: 1.1rem;
  }
  
  .instruction-text {
    font-size: 1.2rem;
  }
  
  .action-button {
    width: 100%;
  }
}
</style> 